/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconLoading from '../../images/loading.svg'
import { useIntl } from 'gatsby-plugin-react-intl'

// Custom hooks
import useSiteSearch from './service/useSiteSearch'
import useScrollBlock from './service/useScrollBlock'
// styles
import './search.scss'

// Icons
import {
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import iconPadrao from '../../images/icon/card-share-lwart.png'

const search = (props) => {
  const [query, setQuery] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const {
    result,
    hasMore,
    loading,
    sendEvent,
    reset
  } = useSiteSearch(query, pageNumber)
  const { blockScroll, allowScroll } = useScrollBlock()

  const observer = useRef()
  const lastResultElementRef = useCallback(node => {
    if (loading) return 0
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch (e) {
    setQuery(e)
    setPageNumber(0)
  }

  useEffect(() => {
    props.active && blockScroll()
  }, [props.active])

  function cleanUp () {
    reset()
    setQuery('')
    allowScroll()
    props.setActive(false)
  }

  const intl = useIntl()

  return (
    props.active && (
      <>
        <div className="w-100 vh-100 position-fixed" style={searchContainer}>
          <div className="modal-dialog my-5" id="search-dialog" role="document">
            <div className="modal-content pb-4 pt-4">
              <div className="modal-header d-flex align-items-center border-0 pr-3 pl-3 pt-0 pb-2">
                <div>
                  <h5 className="mb-0" style={blueFont}>{intl.formatMessage({ id: 'components.search_modal.title' })}Pesquise no nosso site</h5>
                </div>
                {/* {console.log(loading)} */}
                <button type="button" className="bg-transparent border-0 p-0"
                  onClick={() => {
                    sendEvent('close')
                    cleanUp()
                  }}>
                  <span className="h4 font-Spinnaker mb-0 d-flex align-items-start" aria-hidden="true" style={blueFont}>&#88;</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <div className="d-flex align-items-center pr-3 pl-3 pb-0 pt-0">
                  <input type="text" className="form-control font-Spinnaker" style={{ ...inputGreen, ...blueFont }} placeholder={intl.formatMessage({ id: 'components.search_modal.placeholder' })} onChange={(e) => handleSearch(e.target.value)} />
                  {
                    loading
                      ? (
                        <img src={iconLoading} className="loader position-absolute" style={searchIconPosition} />
                      )
                      : (
                        <FontAwesomeIcon icon={faSearch} className="position-absolute" style={searchIconPosition} />
                      )
                  }

                </div>
                <div>
                  { result && result.length > 0 ? (
                    <ul className="p-0 disable-underline-hover infinity-scroll mb-0" id="modal-container-home" style={searchResultUl}>
                      {result.map((item, index) => {
                        // const image = item.pagemap.metatags !== undefined ? item.pagemap.metatags[0]['og:image'] : item.pagemap.cse_image[0].src
                        item.link = item.link.replace('https://www.lwart.com.br/', '')
                        return (
                          <li
                            className="border-bottom hoverSearch p-3"
                            ref={result.length === (index + 1) ? lastResultElementRef : () => (0)}
                            key={item.link}
                          >
                            <Link to={`/${item.link}`}
                              onClick={() => {
                                sendEvent('click', item.link)
                                cleanUp()
                              }}
                            >
                              <p className="mb-2">
                                <b className="font-Spinnaker" style={searchResultLi}>{item.title.replace(/\||lwart/gi, '')}</b><br/>
                                <span className="font-Spinnaker" style={{ ...greenFont, ...sizeLink }}>../{item.link}</span>
                              </p>
                              <div className="d-flex align-items-start">
                                {/* <img src={image} style={searchImage} className="rounded mr-2" /> */}
                                <div>
                                  <p className="font-Spinnaker" dangerouslySetInnerHTML={{ __html: item.htmlSnippet.replace(/(<br>)/g, '') }} style={searchResultparagraph}></p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        )
                      }
                      )}
                    </ul>
                  )
                    : query.length > 0 && (
                      <div className="pt-4">
                        <p className="text-center mb-0 font-Spinnaker">{intl.formatMessage({ id: 'components.search_modal.p1' })}</p>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default search

const searchContainer = {
  zIndex: '200',
  background: '#00000096',
  top: '0'
}

const searchResultLi = {
  fontSize: '1rem',
  color: '#006CB7'
}

const searchResultUl = {
  listStyle: 'none'
}

const searchResultparagraph = {
  fontSize: '.7rem',
  lineHeight: '12px',
  marginBottom: '0px'
}

const searchImage = {
  width: '70px',
  height: '70px'
}

const sizeLink = {
  fontSize: '.8rem'
}

const blueFont = {
  color: '#006CB7'
}

const greenFont = {
  color: '#77B35E'
}

const searchIconPosition = {
  right: '25px',
  color: '#006CB7'
}

const inputGreen = {
  border: '1px solid #77B35E'
}
