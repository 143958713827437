module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src279801825/src/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MDPDDKZ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CloudDog","short_name":"clouddog","start_url":"/","background_color":"#FF7600","theme_color":"#282828","display":"minimal-ui","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91d8cd124586e186fb31d1456d22c8bb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"124effbb-2166-46e2-854c-1825379a3582","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFlMDlkY2RjLWNiOGMtNDI3MC04ZWIxLTY5MzY4Yzc4ODJhZCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMTI0ZWZmYmItMjE2Ni00NmUyLTg1NGMtMTgyNTM3OWEzNTgyIiwiaWF0IjoxNjY4MDEzMjY4fQ.RLofG93hopsuv267kc2XYkEOIu_ersqxVRHLw9s84vs"},"prd":{"siteId":"124effbb-2166-46e2-854c-1825379a3582","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImFlMDlkY2RjLWNiOGMtNDI3MC04ZWIxLTY5MzY4Yzc4ODJhZCIsInVzZXIiOiI1ZGQzMzRjNC04YTIxLTQ3MGQtOTFhYi1hNzE4MTIyMTRjZDEiLCJzaXRlIjoiMTI0ZWZmYmItMjE2Ni00NmUyLTg1NGMtMTgyNTM3OWEzNTgyIiwiaWF0IjoxNjY4MDEzMjY4fQ.RLofG93hopsuv267kc2XYkEOIu_ersqxVRHLw9s84vs"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src279801825/src/src/intl","languages":["pt","en","es"],"defaultLanguage":"pt","redirect":true,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
