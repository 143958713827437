exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-area-cliente-confirmar-conta-js": () => import("./../../../src/pages/area-cliente/confirmar-conta.js" /* webpackChunkName: "component---src-pages-area-cliente-confirmar-conta-js" */),
  "component---src-pages-area-cliente-criar-conta-js": () => import("./../../../src/pages/area-cliente/criar-conta.js" /* webpackChunkName: "component---src-pages-area-cliente-criar-conta-js" */),
  "component---src-pages-area-cliente-esqueci-a-senha-js": () => import("./../../../src/pages/area-cliente/esqueci-a-senha.js" /* webpackChunkName: "component---src-pages-area-cliente-esqueci-a-senha-js" */),
  "component---src-pages-area-cliente-js": () => import("./../../../src/pages/area-cliente.js" /* webpackChunkName: "component---src-pages-area-cliente-js" */),
  "component---src-pages-assessoria-ambiental-js": () => import("./../../../src/pages/assessoria-ambiental.js" /* webpackChunkName: "component---src-pages-assessoria-ambiental-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coleta-de-oleo-lubrificante-usado-js": () => import("./../../../src/pages/coleta-de-oleo-lubrificante-usado.js" /* webpackChunkName: "component---src-pages-coleta-de-oleo-lubrificante-usado-js" */),
  "component---src-pages-coleta-e-destinacao-de-residuos-js": () => import("./../../../src/pages/coleta-e-destinacao-de-residuos.js" /* webpackChunkName: "component---src-pages-coleta-e-destinacao-de-residuos-js" */),
  "component---src-pages-fale-com-a-gente-js": () => import("./../../../src/pages/fale-com-a-gente.js" /* webpackChunkName: "component---src-pages-fale-com-a-gente-js" */),
  "component---src-pages-fale-com-ri-js": () => import("./../../../src/pages/fale-com-ri.js" /* webpackChunkName: "component---src-pages-fale-com-ri-js" */),
  "component---src-pages-fornecedores-js": () => import("./../../../src/pages/fornecedores.js" /* webpackChunkName: "component---src-pages-fornecedores-js" */),
  "component---src-pages-g-2-js": () => import("./../../../src/pages/g2.js" /* webpackChunkName: "component---src-pages-g-2-js" */),
  "component---src-pages-gente-js": () => import("./../../../src/pages/gente.js" /* webpackChunkName: "component---src-pages-gente-js" */),
  "component---src-pages-gestao-de-residuos-js": () => import("./../../../src/pages/gestao-de-residuos.js" /* webpackChunkName: "component---src-pages-gestao-de-residuos-js" */),
  "component---src-pages-gestao-total-de-residuos-js": () => import("./../../../src/pages/gestao-total-de-residuos.js" /* webpackChunkName: "component---src-pages-gestao-total-de-residuos-js" */),
  "component---src-pages-grau-alimenticio-js": () => import("./../../../src/pages/grau-alimenticio.js" /* webpackChunkName: "component---src-pages-grau-alimenticio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacoes-bonds-js": () => import("./../../../src/pages/informacoes-bonds.js" /* webpackChunkName: "component---src-pages-informacoes-bonds-js" */),
  "component---src-pages-informacoes-financeiras-js": () => import("./../../../src/pages/informacoes-financeiras.js" /* webpackChunkName: "component---src-pages-informacoes-financeiras-js" */),
  "component---src-pages-lei-lgpd-js": () => import("./../../../src/pages/lei-lgpd.js" /* webpackChunkName: "component---src-pages-lei-lgpd-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-lw-volt-js": () => import("./../../../src/pages/lw-volt.js" /* webpackChunkName: "component---src-pages-lw-volt-js" */),
  "component---src-pages-lwoma-js": () => import("./../../../src/pages/lwoma.js" /* webpackChunkName: "component---src-pages-lwoma-js" */),
  "component---src-pages-multilingual-js": () => import("./../../../src/pages/multilingual.js" /* webpackChunkName: "component---src-pages-multilingual-js" */),
  "component---src-pages-nossa-essencia-js": () => import("./../../../src/pages/nossa-essencia.js" /* webpackChunkName: "component---src-pages-nossa-essencia-js" */),
  "component---src-pages-nossas-solucoes-js": () => import("./../../../src/pages/nossas-solucoes.js" /* webpackChunkName: "component---src-pages-nossas-solucoes-js" */),
  "component---src-pages-oleos-de-alta-performance-js": () => import("./../../../src/pages/oleos-de-alta-performance.js" /* webpackChunkName: "component---src-pages-oleos-de-alta-performance-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-projeto-h-js": () => import("./../../../src/pages/projeto-h.js" /* webpackChunkName: "component---src-pages-projeto-h-js" */),
  "component---src-pages-scrap-produtivo-e-descaracterizacao-js": () => import("./../../../src/pages/scrap-produtivo-e-descaracterizacao.js" /* webpackChunkName: "component---src-pages-scrap-produtivo-e-descaracterizacao-js" */),
  "component---src-pages-seja-um-fornecedor-js": () => import("./../../../src/pages/seja-um-fornecedor.js" /* webpackChunkName: "component---src-pages-seja-um-fornecedor-js" */),
  "component---src-pages-show-case-js": () => import("./../../../src/pages/show-case.js" /* webpackChunkName: "component---src-pages-show-case-js" */),
  "component---src-pages-solicite-sua-coleta-js": () => import("./../../../src/pages/solicite-sua-coleta.js" /* webpackChunkName: "component---src-pages-solicite-sua-coleta-js" */),
  "component---src-pages-sustentabilidade-js": () => import("./../../../src/pages/sustentabilidade.js" /* webpackChunkName: "component---src-pages-sustentabilidade-js" */),
  "component---src-pages-transbordo-de-residuos-js": () => import("./../../../src/pages/transbordo-de-residuos.js" /* webpackChunkName: "component---src-pages-transbordo-de-residuos-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-profile-template-js": () => import("./../../../src/templates/profile-template.js" /* webpackChunkName: "component---src-templates-profile-template-js" */)
}

