/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react'
import axios from 'axios'
const useSiteSearch = (query, pageNumber) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [result, setResult] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [wait, setWait] = useState(null)

  useEffect(() => {
    setResult([])
  }, [query])

  useEffect(() => {
    clearTimeout(wait)
    setLoading(query.length > 0 && true)
    setError(false)
    // setWait(setTimeout(() => {
    //   axios({
    //     method: 'GET',
    //     url: 'https://www.googleapis.com/customsearch/v1?key=AIzaSyDzLcqrA1-pEtt3HvSJ9g3H2YtRHoWQ7w4&cx=520f5f0fb43385fe8',
    //     params: { q: query, start: pageNumber * 10 }
    //   }).then(res => {
    //     if (!res.data.items) {
    //       setHasMore(false)
    //       setLoading(false)
    //       return 0
    //     }
    //     sendEvent('query', query)
    //     setResult(prevResult => { return res.data.items && [...prevResult, ...res.data.items] })
    //     setHasMore(true)
    //     setLoading(false)
    //   }).catch(e => {
    //     if (axios.isCancel(e)) return 0
    //     setError(true)
    //   })
    // }, 1000))
  }, [query, pageNumber])

  function sendEvent (action, label = 'none') {
    // eslint-disable-next-line no-undef
    if (typeof window !== 'undefined') {
      if (!window.dataLayer) {
        window.dataLayer = []
      }
      window.dataLayer.push({
        event: 'search',
        eventAction: action,
        eventLabel: label
      })
    }
  }

  function reset () {
    setResult([])
    setError(false)
    setHasMore(false)
    setLoading(false)
  }
  return { loading, error, result, hasMore, query, sendEvent, reset }
}

export default useSiteSearch
