/* eslint-disable handle-callback-err */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { Fragment, useEffect } from 'react'
import Footer from '../Footer/footer'
import Header from '../Header/header'
import HeaderMobile from '../Header/headerMobile'
import '../../scss/base.scss'
import '../../scss/plugins/bootstrap.scss'
import '../../fonts/Spinnaker/Spinnaker.css'
import '../../fonts/HendersonSans/Henderson.css'
import Amplify from 'aws-amplify'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
// const awsConfig = require(process.env.ENV ? '../../../config/amplify-dev' : '../../aws-exports')
// Amplify.configure(awsConfig.default)
import awsconfig from '../../aws-exports'
import { useIntl } from 'gatsby-plugin-react-intl'
Amplify.configure(awsconfig)

const Layout = ({ children }) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     (function () {
  //       if (intl.locale === 'pt') {
  //         var s1 = document.createElement('script')
  //         var s0 = document.getElementsByID('script')[0]
  //         s1.async = true
  //         s1.src = 'https://globalbot.ai/init/1/62976724F5D9E6385CB1781A3D46E8A6-2D04-4CC7-9C53-703441DA6934'
  //         s1.charset = 'UTF-8'
  //         s1.setAttribute('crossorigin', '*')
  //         s0.parentNode.insertBefore(s1, s0)
  //       } else {
  //         document.removeItem('script')
  //       }
  //     })()
  //   }, 1000)
  // }, [])

  const intl = useIntl()
  const Top = event => {
    event.preventDefault()
  }

  const scrollFunc = () => {
    let y
    if (typeof window !== 'undefined') {
      y = window.scrollY
    }
    if (y > 1200) {
      document.getElementById('myBtn').className = 'top-link show'
    } else {
      document.getElementById('myBtn').className = 'top-link hide'
    }
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', scrollFunc)
  }

  useEffect(() => {
    if (document.getElementById('gbt-frame')) {
      if (intl.locale === 'pt') {
        document.getElementById('gbt-frame').className = 'd-block'
        document.getElementById('gbt-frame').className = 'chatbot'
      } else {
        document.getElementById('gbt-frame').className = 'd-none'
      }
    }
  }, [intl.locale])

  return (
    <Fragment>
      {typeof window !== 'undefined' &&
        <a className="top-link hide" href="#topo" id="myBtn">
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 6'><path d="M12 6H0l6-6z" /></svg>
        </a>}
      <div>
        <HeaderMobile />
        <Header initialDarkBackground={false} />
        <main>
          {children}
        </main>
        <Footer />
        <script src="https://globalbot.ai/init/1/62976724F5D9E6385CB1781A3D46E8A6-2D04-4CC7-9C53-703441DA6934"></script>
      </div>
    </Fragment>
  )
}

export default Layout
// export default injectIntl(Layout)
